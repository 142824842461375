<template>
  <form @submit.prevent="saveInsurance" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Company Name<span class="text-red-400">*</span></label>
      <input v-model="insurance.company_name" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Company Name..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Policy Number<span class="text-red-400">*</span></label>
      <input v-model="insurance.policy_number" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Policy Number..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"> Level of Cover<span class="text-red-400">*</span></label>
      <select class="bge-input bge-select rounded" v-model="insurance.level_of_cover">
        <option value="Comprehensive">Comprehensive</option>
        <option value="Business Use">Business Use</option>
        <option value="S,D & P Only">S,D & P Only</option>
        <option value="Third Party">Third Party</option>
        <option value="Business & S,D & P">Business & S,D & P</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Contract Begin<span class="text-red-400">*</span></label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="insurance.contract_begin"
        format="dd/MM/yyyy"
        @selected="startDateSelected()"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Contract End<span class="text-red-400">*</span></label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="insurance.contract_end"
        :open-date="openEndDate"
        :disabledDates="disabledEndDates"
        format="dd/MM/yyyy"
        @closed="endDateClosed()"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Reminder Date<span class="text-red-400">*</span></label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="insurance.reminder_date"
        use-utc="false"
        format="dd/MM/yyyy"
      ></datepicker>
    </div>

    <div class="w-full">
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          type="button"
          @click="saveInsurance"
          class="
          float-right
          mt-5
          flex
          items-center
          bg-green-200
          hover:bg-green-300
          py-1
          px-3
          rounded
          border border-green-400
          hover:border-green-500
          text-secondary
          hover:shadow
          active:shadow-inner
          transition
          duration-100
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save Insurance</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
import futureDateByNMonth from "@/utils/futureDateByNMonth";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "AddEditInsurance",
  components: {
    Datepicker,
    IsAuthorized,
  },
  props: {
    insurance: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      openEndDate: new Date(),
      disabledEndDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  mounted() {
    if (this.insurance.contract_begin) {
      this.openEndDate = new Date(this.insurance.contract_begin);
      this.disabledEndDates.to = new Date(this.insurance.contract_begin);
    }
  },
  methods: {
    startDateSelected(e) {
      this.$nextTick(() => {
        this.openEndDate = new Date(this.insurance.contract_begin);
        this.disabledEndDates.to = new Date(this.insurance.contract_begin);
      });
    },
    endDateClosed(e) {
      var reminderDate = this.$moment(this.insurance.contract_end)
        .subtract(31, "d")
        .toDate();
      if (
        this.$validator.isBefore(
          reminderDate instanceof Date ? reminderDate.toDateString() : reminderDate,
          this.insurance.contract_begin instanceof Date ? this.insurance.contract_begin.toDateString() : this.insurance.contract_begin
        )
      ) {
        this.insurance.reminder_date = this.$moment(this.insurance.contract_end)
          .subtract(1, "d")
          .toDate();
      } else {
        this.insurance.reminder_date = reminderDate;
      }
    },
    saveInsurance: function() {
      this.$emit("complete", this.insurance);
    },
    deleteInsurance: function() {
      this.$emit("delete", this.insurance);
    },
  },
};
</script>
